import { createSlice } from '@reduxjs/toolkit';

export const positionsSlice = createSlice({
  name: 'positions',
  initialState: {
    positionsList: null,
    positionsRecordEdit: null,
    positionsShowModal: false,
    positionsErrorMessage: null,
    positionsStatus: 'pending'
  },
  reducers: {
    listPositions: (state, { payload }) => {
      state.positionsList = (payload) ? payload : null;
    },
    statusPositions: (state, { payload }) => {
      state.positionsErrorMessage = (payload) ? payload.error : null;
      state.positionsStatus = (payload) ? payload.status : 'pending';
    },
    recordEditPositions: (state, { payload }) => {
      state.positionsRecordEdit = (payload) ? payload : null;
    },
    showModalPositions: (state, { payload }) => {
      state.positionsShowModal = payload;
    },
  }
});

export const { listPositions, statusPositions, recordEditPositions, showModalPositions } = positionsSlice.actions;