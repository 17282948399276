import { createSlice } from '@reduxjs/toolkit';

export const prioritySlice = createSlice({
  name: 'priority',
  initialState: {
    priorityList: null,
    priorityRecordEdit: null,
    priorityShowModal: false,
    priorityErrorMessage: null,
    priorityStatus: 'pending'
  },
  reducers: {
    listPriority: (state, { payload }) => {
      state.priorityList = (payload) ? payload : null;
    },
    statusPriority: (state, { payload }) => {
      state.priorityErrorMessage = (payload) ? payload.error : null;
      state.priorityStatus = (payload) ? payload.status : 'pending';
    },
    recordEditPriority: (state, { payload }) => {
      state.priorityRecordEdit = (payload) ? payload : null;
    },
    showModalPriority: (state, { payload }) => {
      state.priorityShowModal = payload;
    },
  }
});

export const { listPriority, statusPriority, recordEditPriority, showModalPriority } = prioritySlice.actions;