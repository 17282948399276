import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    countryList: null,
    countryErrorMessage: null,
    countryStatus: 'pending'
  },
  reducers: {
    listCountries: (state, { payload }) => {
      state.countryList = (payload) ? payload : null;
    },
    statusCountries: (state, { payload }) => {
      state.countryErrorMessage = (payload) ? payload.error : null;
      state.countryStatus = (payload) ? payload.status : 'pending';
    },
  }
});

export const { listCountries, statusCountries } = countrySlice.actions;