import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isExpired, decodeToken } from "react-jwt";
import * as _ from 'lodash';
import { startLogout,refreshToken } from '../redux/auth/thunks';
import { handleLogin } from '../redux/auth/authSlice';
import { useLocation } from 'react-router-dom';
import { checkUser } from "../api";


export const useCheckAuth = () => {
  const { status } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const validateToken = async() => {
    const tokenStorage = await localStorage.getItem('token');
    //const userCheck= useCheck();

    if (!tokenStorage) return dispatch(startLogout());

    const tokenDecode = decodeToken(tokenStorage);
    const isMyTokenExpired = isExpired(tokenStorage);

    if (isMyTokenExpired) return dispatch(startLogout());
    const payload = {
      token: tokenStorage,
      user: (!_.isUndefined(tokenDecode['user'])) ? tokenDecode['user'] : null,
    }

    return dispatch(handleLogin(payload));
    //return dispatch(refreshToken());
  };

  useEffect(() => {
    validateToken();
  }, [pathname]);

  return status;
}
