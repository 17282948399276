import { createSlice } from '@reduxjs/toolkit';

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    commentsList: null,
    commentsRecordEdit: null,
    commentsShowModal: false,
    commentsErrorMessage: null,
    commentsStatus: 'pending'
  },
  reducers: {
    listcomments: (state, { payload }) => {
      state.commentsList = (payload) ? payload : null;
    },
    statuscomments: (state, { payload }) => {
      state.commentsErrorMessage = (payload) ? payload.error : null;
      state.commentsStatus = (payload) ? payload.status : 'pending';
    },
    recordEditcomments: (state, { payload }) => {
      state.commentsRecordEdit = (payload) ? payload : null;
    },
    showModalcomments: (state, { payload }) => {
      state.commentsShowModal = payload;
    },
  }
});

export const { listcomments, statuscomments, recordEditcomments, showModalcomments } = commentsSlice.actions;