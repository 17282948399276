import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import { Index } from './index';
import { NotificationProvider } from './context/NotificationContext';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <PrimeReactProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <SnackbarProvider
            style={{zIndex:2147483647}}
            maxSnack={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{
              containerRoot:
                "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99 mt-20",
            }}
          >
            <NotificationProvider>
              <Index />
            </NotificationProvider>
          </SnackbarProvider>

        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </PrimeReactProvider>

)