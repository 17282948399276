const locale = {
  "Dashboard": "Dashboard",
  "Accounts"  : "Cuentas",
  "Employees": "Empleados",
  "Task Mangement" : "Gestion de Tareas",
  "Lead" : "Seguimiento",
  "Files" : "Expediente",
  
  "Settings": "Configuración",
  "Status": "Estatus",
  "Task type": "Tipo de tarea",
  "Tasks": "Tareas",
  "Subtasks": "Subtareas",
  "Workspaces": "Espacio de trabajo",
  "Sites": "Sitios",
  "Departments": "Departamentos",
  "Users": "Usuarios",
  "Role": "Roles",
  "Tags": "Tags",
  "Functions": "Funciones/Actividades",
  "Positions": "Cargos",
  "Priority": "Prioridades",
  "Indicators": "Indicadores",
  "Account type": "Tipo de cuenta",
  "Social Network": "Redes sociales",
  "Persons": "Personas",
  "External Reports": "Reportes Externos",
  "Register": "Registrar",
  "View": "Ver",
  "Ecommerce": "Tienda",
  "Setting": "Configuración",
  "Categories": "Categorías",
  "Products": "Productos",
  "Stores": "Tiendas",
  "Brands": "Marcas",
  "Taxes": "Impuestos",
  "Manufacturers": "Fabricantes",
  "Price list": "Lista de precios",
  "Orders": "Pedidos",
};

export default locale;
