import { createSlice } from '@reduxjs/toolkit';

export const siteSlice = createSlice({
  name: 'sites',
  initialState: {
    siteList: null,
    siteRecordEdit: null,
    siteShowModal: false,
    siteErrorMessage: null,
    siteStatus: 'pending'
  },
  reducers: {
    listSite: (state, { payload }) => {
      state.siteList = (payload) ? payload : null;
    },
    statusSite: (state, { payload }) => {
      state.siteErrorMessage = (payload) ? payload.error : null;
      state.siteStatus = (payload) ? payload.status : 'pending';
    },
    recordEditSite: (state, { payload }) => {
      state.siteRecordEdit = (payload) ? payload : null;
    },
    showModalSite: (state, { payload }) => {
      state.siteShowModal = payload;
    },
  }
});

export const { listSite, statusSite, recordEditSite, showModalSite } = siteSlice.actions;