import React from 'react';
import { Imagesdata } from '@/constants/Images';
import { getEnvVariables } from '@/constants/getEnvVariables';

const { DOMAIN } = getEnvVariables();


export const showLogoAuth = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<></>);
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("FondoAuthRsAgency")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return (<img src={Imagesdata("LogoLoginGassapp")} className="ht-xl-60p wd-md-100p wd-xl-60p mx-auto mt-8 mb-5" alt="logo" />);
  }
  if(DOMAIN == 'dataprocess'){
    return (<img src={Imagesdata("FondoAuthDataprocess")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'ipusa'){
    return (<img src={Imagesdata("FondoAuthIpusa")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("FondoAuthOmnix")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("FondoAuthSunGrow")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("FondoAuthWitMakers")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("FondoAuthTcbDrones")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'bravat'){
    return (<img src={Imagesdata("FondoAuthBravat")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoCecat")} className="ht-xl-50p wd-md-60p wd-xl-50p mx-auto" alt="logo" />);
  }
  if(DOMAIN == 'infinytask'){
    return (<></>);
  }
}

export const showBackgroundAuth = () => {
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return {backgroundImage: `url(${Imagesdata("FondoAuthInfiny")})`, backgroundSize: '100% 100%', backgroundPosition: 'bottom' , backgroundRepeat: 'no-repeat' }; 
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return {};
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return {backgroundImage: `url(${Imagesdata("FondoLoginGassapp")})`};
  }
  if(DOMAIN == 'dataprocess'){
    return {};
  }
  if(DOMAIN == 'ipusa'){
    return {};
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return {};
  }
  if(DOMAIN == 'sungrow'){
    return {};
  }
  if(DOMAIN == 'witmakers'){
    return {};
  }
  if(DOMAIN == 'tcbdrones'){
    return {backgroundImage: `url(${Imagesdata("FondoLoginTcbDrones")})`};
  }
  if(DOMAIN == 'bravat'){
    return {};
  }
  if(DOMAIN == 'cecat'){
    return {backgroundImage: `url(${Imagesdata("FondoAuthCecat")})`, backgroundSize: '60% 60%', backgroundPosition: 'bottom' , backgroundRepeat: 'no-repeat' }; 
  }
  if(DOMAIN == 'infinytask'){
    return {backgroundImage: `url(${Imagesdata("FondoAuthInfiny")})`, backgroundSize: '100% 100%', backgroundPosition: 'bottom' , backgroundRepeat: 'no-repeat' }; 
  }
}

export const showLogoHeader = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (
      <>
        <img src={Imagesdata("LogoInfiny")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoInfiny")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return (
      <>
        <img src={Imagesdata("LogoRsAgency")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoRsAgency")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return (
      <>
        <img src={Imagesdata("LogoGassapp")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoGassapp")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'dataprocess'){
    return (
      <>
        <img src={Imagesdata("LogoDataprocess")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoDataprocess")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return (
      <>
        <img src={Imagesdata("LogoOmnix")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoOmnix")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'sungrow'){
    return (
      <>
        <img src={Imagesdata("LogoSunGrow")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoSunGrow")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'witmakers'){
    return (
      <>
        <img src={Imagesdata("LogoWitMakers")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoWitMakers")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'tcbdrones'){
    return (
      <>
        <img src={Imagesdata("LogoTcbDrones")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoTcbDrones")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'bravat'){
    return (
      <>
        <img src={Imagesdata("LogoBravat")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoBravat")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'cecat'){
    return (
      <>
        <img src={Imagesdata("LogoCecat")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoCecat")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'infinytask'){
    return (
      <>
        <img src={Imagesdata("LogoInfiny")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoInfiny")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
}

export const showLogoSidebar = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<img src={Imagesdata("LogoMobileInfiny")} className="main-logo" alt="logo" style={{ height: '180%' }}/>)
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("LogoRsAgency")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return (<img src={Imagesdata("LogoGassapp")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'dataprocess'){
    return (<img src={Imagesdata("LogoDataprocess")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'ipusa'){
    return (<img src={Imagesdata("LogoIpusa")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("LogoOmnix")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("LogoSunGrow")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("LogoWitMakers")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("LogoTcbDrones")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'bravat'){
    return (<img src={Imagesdata("LogoBravat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoCecat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'infinytask'){
    return (<img src={Imagesdata("LogoMobileInfiny")} className="main-logo" alt="logo" style={{ height: '180%' }}/>)
  }
}

export const showLogoMobileSidebar = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<img src={Imagesdata("LogoMobileCecat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("LogoMobileRsAgency")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return (<img src={Imagesdata("LogoMobileGassapp")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'dataprocess'){
    return (<img src={Imagesdata("LogoMobileDataprocess")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'ipusa'){
    return (<img src={Imagesdata("LogoMobileIpusa")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("LogoOmnix")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("LogoMobileSunGrow")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("LogoMobileWitMakers")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("LogoMobileTcbDrones")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'bravat'){
    return (<img src={Imagesdata("LogoMobileBravat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoMobileCecat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'infinytask'){
    return (<img src={Imagesdata("LogoMobileInfiny")} className="main-logo" alt="logo"/>)
  }
}