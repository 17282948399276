import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
} from "react";
import { toast, Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

const NotificationContext = createContext("alert-notifications");

export const NotificationProvider = ({ children }) => {

  const defaultNotification = (message) => toast(message, {
    style: {
      padding: '16px',
      width: '500px',
      color: '#fff',
      backgroundColor: '#025cd8',
    },
    position: 'top-right',
  });

  const successNotification = (message) => toast(message, {
    style: {
      padding: '16px',
      width: '500px',
      color: '#fff',
      backgroundColor: '#29bf74',

    },
    position: 'top-right',
  });

  const dangerNotification = (message) => toast(message, {
    style: {
      padding: '16px',
      width: '500px',
      color: '#fff',
      backgroundColor: ' #ff5b51',

    },
    position: 'top-right',
  });

  const warningNotification = (message) => toast(message, {
    style: {
      padding: '16px',
      width: '500px',
      color: '#fff',
      backgroundColor: '#ffc720',

    },
    position: 'top-right',
  });

  const infoNotification = (message) => toast(message, {
    style: {
      padding: '16px',
      width: '500px',
      color: '#fff',
      backgroundColor: '#00b9ff',
    },
    position: 'top-right',
  });

  return (
    <>
      <Toaster />
      <NotificationContext.Provider
        value={{
          defaultNotification,
          successNotification,
          dangerNotification,
          warningNotification,
          infoNotification,
        }}
      >
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export const useNotifications = () => {
  return useContext(NotificationContext);
};
