import { createSlice } from '@reduxjs/toolkit';

export const functionsSlice = createSlice({
  name: 'funcitons',
  initialState: {
    functionsList: null,
    functionsRecordEdit: null,
    functionsShowModal: false,
    functionsErrorMessage: null,
    functionsStatus: 'pending'
  },
  reducers: {
    listFunctions: (state, { payload }) => {
      state.functionsList = (payload) ? payload : null;
    },
    statusFunctions: (state, { payload }) => {
      state.functionsErrorMessage = (payload) ? payload.error : null;
      state.functionsStatus = (payload) ? payload.status : 'pending';
    },
    recordEditFunctions: (state, { payload }) => {
      state.functionsRecordEdit = (payload) ? payload : null;
    },
    showModalFunctions: (state, { payload }) => {
      state.functionsShowModal = payload;
    },
  }
});

export const { listFunctions, statusFunctions, recordEditFunctions, showModalFunctions } = functionsSlice.actions;