import { createSlice } from '@reduxjs/toolkit';

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState: {
    workspaceList: null,
    workspaceRecordEdit: null,
    workspaceShowModal: false,
    workspaceErrorMessage: null,
    workspaceStatus: 'pending'
  },
  reducers: {
    listWorkspace: (state, { payload }) => {
      state.workspaceList = (payload) ? payload : null;
    },
    statusWorkspace: (state, { payload }) => {
      state.workspaceErrorMessage = (payload) ? payload.error : null;
      state.workspaceStatus = (payload) ? payload.status : 'pending';
    },
    recordEditWorkspace: (state, { payload }) => {
      state.workspaceRecordEdit = (payload) ? payload : null;
    },
    showModalWorkspace: (state, { payload }) => {
      state.workspaceShowModal = payload;
    },
  }
});

export const { listWorkspace, statusWorkspace, recordEditWorkspace, showModalWorkspace } = workspacesSlice.actions;