import { createSlice } from '@reduxjs/toolkit';

export const subTaskSlice = createSlice({
  name: 'subTask',
  initialState: {
    subTaskList: null,
    subTaskRecordEdit: null,
    subTaskShowModal: false,
    subTaskErrorMessage: null,
    subTaskStatus: 'pending'
  },
  reducers: {
    listSubTask: (state, { payload }) => {
      state.subTaskList = (payload) ? payload : null;
    },
    statusSubTask: (state, { payload }) => {
      state.subTaskErrorMessage = (payload) ? payload.error : null;
      state.subTaskStatus = (payload) ? payload.status : 'pending';
    },
    recordEditSubTask: (state, { payload }) => {
      state.subTaskRecordEdit = (payload) ? payload : null;
    },
    showModalSubTask: (state, { payload }) => {
      state.subTaskShowModal = payload;
    },
  }
});

export const { listSubTask, statusSubTask, recordEditSubTask, showModalSubTask } = subTaskSlice.actions;