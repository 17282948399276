import { createSlice } from '@reduxjs/toolkit';

export const statusSlice = createSlice({
  name: 'status',
  initialState: {
    statusList: null,
    statusRecordEdit: null,
    statusShowModal: false,
    statusErrorMessage: null,
    statusStatus: 'pending'
  },
  reducers: {
    listStatus: (state, { payload }) => {
      state.statusList = (payload) ? payload : null;
    },
    statusStatus: (state, { payload }) => {
      state.statusErrorMessage = (payload) ? payload.error : null;
      state.statusStatus = (payload) ? payload.status : 'pending';
    },
    recordEditStatus: (state, { payload }) => {
      state.statusRecordEdit = (payload) ? payload : null;
    },
    showModalStatus: (state, { payload }) => {
      state.statusShowModal = payload;
    },
  }
});

export const { listStatus, statusStatus, recordEditStatus, showModalStatus } = statusSlice.actions;