import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    usersList: null,
    usersRecordEdit: null,
    usersShowModal: false,
    usersViewModal: false,
    usersErrorMessage: null,
    usersStatus: 'pending'
  },
  reducers: {
    listUsers: (state, { payload }) => {
      state.usersList = (payload) ? payload : null;
    },
    statusUsers: (state, { payload }) => {
      state.usersErrorMessage = (payload) ? payload.error : null;
      state.usersStatus = (payload) ? payload.status : 'pending';
    },
    recordEditUsers: (state, { payload }) => {
      state.usersRecordEdit = (payload) ? payload : null;
    },
    showModalUsers: (state, { payload }) => {
      state.usersShowModal = payload;
    },
    showViewModalUsers: (state, { payload }) => {
      state.usersViewModal = payload;
    },
  }
});

export const { listUsers, statusUsers, recordEditUsers, showModalUsers, showViewModalUsers } = usersSlice.actions;