import { lazy } from 'react';

const DashboardPage = lazy(() => import('../pages/intranet/dashboard/DashboardPage'));
const BlankPage = lazy(() => import('../pages/blank/BlankPage'));
const AccountsPage = lazy(() => import('../pages/intranet/accounts/AccountsPage'));
const AccountsFormPage = lazy(() => import('../pages/intranet/accounts/AccountsFormPage'));
const CommentsPage = lazy(() => import('../pages/intranet/comments/CommentsPage'));
const PersonsPage = lazy(() => import('../pages/intranet/persons/PersonsPage'));
const TaskManagementPage = lazy(() => import('../pages/intranet/task-management/TaskManagementPage'));
const TaskManagementFormPage = lazy(() => import('../pages/intranet/task-management/TaskManagementForm'));

const TaskManagementTypePage = lazy(() => import('../pages/intranet/task-management-type/TaskManagementPage'));
const TaskManagementTypeFormPage = lazy(() => import('../pages/intranet/task-management-type/TaskManagementForm'));

const DynamicFormPage = lazy(() => import('../pages/intranet/form-builder/DynamicFormPage'));
const DynamicForm = lazy(() => import('../pages/intranet/form-builder/DynamicForm'));

const StatusPage = lazy(() => import('../pages/intranet/configuration/status/StatusPage'));
const TaskPage = lazy(() => import('../pages/intranet/configuration/task/TaskPage'));
const SubTaskPage = lazy(() => import('../pages/intranet/configuration/sub-task/SubTaskPage'));
const SitePage = lazy(() => import('../pages/intranet/configuration/sites/SitePage'));
const WorkspacePage = lazy(() => import('../pages/intranet/configuration/workspaces/WorkspacePage'));
const DepartmentsPage = lazy(() => import('../pages/intranet/configuration/departments/DepartmentPage'));
const UsersPage = lazy(() => import('../pages/intranet/configuration/users/UsersPage'));
const RolesPage = lazy(() => import('../pages/intranet/configuration/roles/RolesPage'));
const RolesAddPage = lazy(() => import('../pages/intranet/configuration/roles/RolesAdd'));
const RolesUpdPage = lazy(() => import('../pages/intranet/configuration/roles/RolesUpd'));
const PriorityPage = lazy(() => import('../pages/intranet/configuration/priority/PriorityPage'));
const TagsPage = lazy(() => import('../pages/intranet/configuration/tags/TagsPage'));
const FunctionsPage = lazy(() => import('../pages/intranet/configuration/functions/FunctionsPage'));
const PositionsPage = lazy(() => import('../pages/intranet/configuration/positions/PositionsPage'));
const TasksTypesPage = lazy(() => import('../pages/intranet/configuration/tasks-types/TasksTypesPage'));
const AccountTypesPage = lazy(() => import('../pages/intranet/configuration/account-types/AccountTypesPage'));
const SocialNetworkPage = lazy(() => import('../pages/intranet/configuration/social-network/SocialNetworkPage'));
const ExternalReportPage = lazy(() => import('../pages/intranet/external-reports/ExternalReportPage'));
const ExternalReportViewPage = lazy(() => import('../pages/intranet/external-reports/view/ExternalReportViewPage'));


//Ecommerce
const SettingPage = lazy(() => import('../pages/intranet/ecommerce/setting/SettingPage'));
const CategoriesPage = lazy(() => import('../pages/intranet/ecommerce/categories/CategoriesPage'));
const ProductsPage = lazy(() => import('../pages/intranet/ecommerce/products/ProductsPage'));
const ProductsFormPage = lazy(() => import('../pages/intranet/ecommerce/products/ProductsFormPage'));
const StoresPage = lazy(() => import('../pages/intranet/ecommerce/stores/StoresPage'));
const BrandsPage = lazy(() => import('../pages/intranet/ecommerce/brands/BrandsPage'));
const TagsEcomPage = lazy(() => import('../pages/intranet/ecommerce/tags/TagsPage'));
const TaxesPage = lazy(() => import('../pages/intranet/ecommerce/taxes/TaxesPage'));
const ManufacturersPage = lazy(() => import('../pages/intranet/ecommerce/manufacturers/ManufacturersPage'));
const PriceList = lazy(() => import('../pages/intranet/ecommerce/price-list/PriceListPage'));
const PriceListForm = lazy(() => import('../pages/intranet/ecommerce/price-list/PricesListForm'));
const OrdersPage = lazy(() => import('../pages/intranet/ecommerce/orders/OrdersPage'));

export const RouterData = [
  { path: `/`, element: <DashboardPage /> },
  { path: `/dashboard`, element: <DashboardPage /> },
  { path: `/accounts/:type_id?`, element: <AccountsPage /> },
  { path: `/accounts/:type_id?/form/:id?`, element: <AccountsFormPage /> },
  { path: `/files`, element: <CommentsPage /> },
  { path: `/persons`, element: <PersonsPage /> },
  { path: `/task-management`, element: <TaskManagementPage /> },
  { path: `/task-management/form`, element: <TaskManagementFormPage /> },
  { path: `/task-management/form/:id`, element: <TaskManagementFormPage /> },
  { path: `/task-management/type/:type_id`, element: <TaskManagementTypePage /> },
  { path: `/task-management/type/:type_id/form`, element: <TaskManagementTypeFormPage /> },
  { path: `/task-management/type/:type_id/form/:id`, element: <TaskManagementTypeFormPage /> },
  { path: `/status`, element: <StatusPage /> },
  { path: `/tasks`, element: <TaskPage /> },
  { path: `/subtasks`, element: <SubTaskPage /> },
  { path: `/workspaces`, element: <WorkspacePage /> },
  { path: `/priority`, element: <PriorityPage /> },
  { path: `/sites`, element: <SitePage /> },
  { path: `/departments`, element: <DepartmentsPage /> },
  { path: `/users`, element: <UsersPage /> },
  { path: `/roles`, element: <RolesPage /> },
  { path: `/roles/new`, element: <RolesAddPage /> },
  { path: `/roles/edit/:id`, element: <RolesUpdPage /> },
  { path: `/tags`, element: <TagsPage /> },
  { path: `/functions`, element: <FunctionsPage /> },
  { path: `/positions`, element: <PositionsPage /> },
  { path: `/tasks-types`, element: <TasksTypesPage /> },
  { path: `/account-types`, element: <AccountTypesPage /> },
  { path: `/social-network`, element: <SocialNetworkPage /> },
  { path: `/external-report`, element: <ExternalReportPage /> },
  { path: `/external-report-view`, element: <ExternalReportViewPage /> },
  { path: `/dynamic-form`, element: <DynamicFormPage /> },
  { path: `/dynamic-form/form`, element: <DynamicForm /> },
  { path: `/dynamic-form/form/:id`, element: <DynamicForm /> },
  { path: `/ecommerce/setting`, element: <SettingPage /> },
  { path: `/ecommerce/categories`, element: <CategoriesPage /> },
  { path: `/ecommerce/products`, element: <ProductsPage /> },
  { path: `/ecommerce/products/form`, element: <ProductsFormPage /> },
  { path: `/ecommerce/products/form/:id`, element: <ProductsFormPage /> },
  { path: `/ecommerce/stores`, element: <StoresPage /> },
  { path: `/ecommerce/brands`, element: <BrandsPage /> },
  { path: `/ecommerce/tags`, element: <TagsEcomPage /> },
  { path: `/ecommerce/taxes`, element: <TaxesPage /> },
  { path: `/ecommerce/manufacturers`, element: <ManufacturersPage /> },
  { path: `/ecommerce/prices-list`, element: <PriceList /> },
  { path: `/ecommerce/prices-list/form`, element: <PriceListForm /> },
  { path: `/ecommerce/prices-list/form/:id`, element: <PriceListForm /> },
  { path: `/ecommerce/orders`, element: <OrdersPage /> },

  /* { path: `${import.meta.env.BASE_URL}fixed-asset`, element: <FixedAssetPage /> },
  { path: `${import.meta.env.BASE_URL}inmovables-asset`, element: <InmovablesAssetPage /> },
  { path: `${import.meta.env.BASE_URL}movables-asset`, element: <MovablesAssetPage /> },
  { path: `${import.meta.env.BASE_URL}rental-asset`, element: <RentalAssetPage /> }, */
];