import { createSlice } from '@reduxjs/toolkit';

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskList: null,
    taskRecordEdit: null,
    taskShowModal: false,
    taskErrorMessage: null,
    taskStatus: 'pending'
  },
  reducers: {
    listTask: (state, { payload }) => {
      state.taskList = (payload) ? payload : null;
    },
    statusTask: (state, { payload }) => {
      state.taskErrorMessage = (payload) ? payload.error : null;
      state.taskStatus = (payload) ? payload.status : 'pending';
    },
    recordEditTask: (state, { payload }) => {
      state.taskRecordEdit = (payload) ? payload : null;
    },
    showModalTask: (state, { payload }) => {
      state.taskShowModal = payload;
    },
  }
});

export const { listTask, statusTask, recordEditTask, showModalTask } = taskSlice.actions;