import { createSlice } from '@reduxjs/toolkit';

export const taskManagerSlice = createSlice({
  name: 'taskManager',
  initialState: {
    taskManagerList: null,
    taskManagerRecordEdit: null,
    taskManagerShowModal: false,
    taskManagerShowview: false,
    taskManagerShowComments: false,
    taskManagerErrorMessage: null,
    taskManagerStatus: 'pending'
  },
  reducers: {
    listTaskManager: (state, { payload }) => {
      state.taskManagerList = (payload) ? payload : null;
    },
    statusTaskManager: (state, { payload }) => {
      state.taskManagerErrorMessage = (payload) ? payload.error : null;
      state.taskManagerStatus = (payload) ? payload.status : 'pending';
    },
    recordEditTaskManager: (state, { payload }) => {
      state.taskManagerRecordEdit = (payload) ? payload : null;
    },
    showModalTaskManager: (state, { payload }) => {
      state.taskManagerShowModal = payload;
    },
    showViewTaskManager: (state, { payload }) => {
      state.taskManagerShowview = payload;
    },
    showCommentTaskManager: (state, { payload }) => {
      state.taskManagerShowComments = payload;
    },
  }
});

export const { listTaskManager, statusTaskManager, recordEditTaskManager, showModalTaskManager, showViewTaskManager, showCommentTaskManager } = taskManagerSlice.actions;