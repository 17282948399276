import { createSlice } from '@reduxjs/toolkit';

export const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    cityList: null,
    cityErrorMessage: null,
    cityStatus: 'pending'
  },
  reducers: {
    listCities: (state, { payload }) => {
      state.cityList = (payload) ? payload : null;
    },
    statusCities: (state, { payload }) => {
      state.cityErrorMessage = (payload) ? payload.error : null;
      state.cityStatus = (payload) ? payload.status : 'pending';
    },
  }
});

export const { listCities, statusCities } = citiesSlice.actions;