import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    accountList: null,
    accountRecordEdit: null,
    accountShowModal: false,
    accountShowModalComments: false,
    accountErrorMessage: null,
    accountStatus: 'pending'
  },
  reducers: {
    listAccount: (state, { payload }) => {
      state.accountList = (payload) ? payload : null;
    },
    statusAccount: (state, { payload }) => {
      state.accountErrorMessage = (payload) ? payload.error : null;
      state.accountStatus = (payload) ? payload.status : 'pending';
    },
    recordEditAccount: (state, { payload }) => {
      state.accountRecordEdit = (payload) ? payload : null;
    },
    showModalAccount: (state, { payload }) => {
      state.accountShowModal = payload;
    },
    showModalCommentsAccount: (state, { payload }) => {
      state.accountShowModalComments = payload;
    },
  }
});

export const { listAccount, statusAccount, recordEditAccount, showModalAccount, showModalCommentsAccount } = accountSlice.actions;