import { getEnvVariables } from '/src/constants/getEnvVariables';
import * as _ from 'lodash';

const { API_URL, DOMAIN } = getEnvVariables();

class ApiCore {

  get = async (url) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;

    } catch (catchError) {
      throw catchError;
    }
  }

  getNotAuth = async (url) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;

    } catch (catchError) {
      throw catchError;
    }
  }

  getFiles = async () => {
    try {
      const token = await localStorage.getItem('token');
      let folder = (DOMAIN == 'localhost') ? 'task_manager' : DOMAIN;

      const respApi = await fetch(`https://files.omnixsf.space/`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then( resp => resp.json() );
      
      if(respApi.code != 201){
        throw respApi.msg;
      }

      let data = respApi.data.filter( item => item.includes(folder) );

      return data;

    } catch (catchError) {
      throw catchError;
    }
  }

  post = async(url, data) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }
  }

  postFormData = async (url, data) => {
    try {
      const token = await localStorage.getItem('token');
      const formData = new FormData();
      
      /* for (let prop in data) {
        Array.isArray(data[prop]) ? formData.append(prop, JSON.stringify(data[prop])) : formData.append(prop, data[prop])
      } */

      for (let prop in data) {
        if (prop === 'files' || prop === 'banner_image_file' || prop === 'logo_file') continue
        Array.isArray(data[prop]) ? formData.append(prop, JSON.stringify(data[prop])) : formData.append(prop, data[prop])
      }
      let count = 0;
      if(_.size(data.files) > 0){
        for (let file in data.files) {
          formData.append(`files[${count}]`, data.files[file])
          count++
        }
      }

      if(_.size(data.banner_image_file) > 0){
        for (let file in data.banner_image_file) {
          formData.append(`banner_image_file[${0}]`, data.banner_image_file[file])
          count++
        }
      }
      if(_.size(data.logo_file) > 0){
        for (let file in data.logo_file) {
          formData.append(`logo_file[${0}]`, data.logo_file[file])
          count++
        }
      }

      formData.append("_method", "post");

      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }
  }

  postFiles = async(url, data) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: data
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }

    /* const formData = new FormData()
    for (let prop in data) {
      if (prop === 'files') continue
      Array.isArray(data[prop]) ? formData.append(prop, JSON.stringify(data[prop])) : formData.append(prop, data[prop])
    }
    let count = 0
    for (let file in data.files) {
      formData.append(`files[${count}]`, data.files[file])
      count++
    }
    return backend.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }) */
  }

  put = async(url, data) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }
  }

  putFormData = async (url, data) => {
    try {
      const token = await localStorage.getItem('token');
      const formData = new FormData();
      
      for (let prop in data) {
        Array.isArray(data[prop]) ? formData.append(prop, JSON.stringify(data[prop])) : formData.append(prop, data[prop])
      }

      formData.append("_method", "put");

      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      }).then( resp => resp.json() );
      
      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }
    /* const formData = new FormData()
    for (let prop in data) {
      if (prop === 'files') continue
      Array.isArray(data[prop]) ? formData.append(prop, JSON.stringify(data[prop])) : formData.append(prop, data[prop])
    }
    let count = 0
    for (let file in data.files) {
      formData.append(`files[${count}]`, data.files[file])
      count++
    }
    return backend.post(url + '?_method=PUT', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }) */
  }

  delete = async(url) => {
    try {
      const token = await localStorage.getItem('token');
      const respApi = await fetch(`${API_URL}${url}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then( resp => resp.json() );

      if(respApi.code != 200){
        throw respApi.msg;
      }

      return respApi.data;
    } catch (catchError) {
      throw catchError;
    }
  }
}

export { ApiCore }