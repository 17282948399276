import { createSlice } from '@reduxjs/toolkit';
import { isExpired, decodeToken } from "react-jwt";
import * as _ from 'lodash';


const initialUser = () => {
  if (typeof window !== "undefined") {
    const tokenStorage = window.localStorage.getItem("token");
    if (!tokenStorage) return null;

    const tokenDecode = decodeToken(tokenStorage);
    const isMyTokenExpired = isExpired(tokenStorage);

    if (isMyTokenExpired) return null;

    return (!_.isUndefined(tokenDecode['user'])) ? tokenDecode['user'] : null
  }
  return null;
};

const initialToken = () => {
  if (typeof window !== "undefined") {
    const tokenStorage = window.localStorage.getItem("token");
    return tokenStorage ? tokenStorage : null;
  }
  return null;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: (!initialUser) ? 'not-authenticated' : 'authenticated',
    token: initialToken(),
    user: initialUser(),
    authErrorMessage: null,
    authStatus: 'pending',
    sessionStatus: 'Offline',
    lastConnection: null,
  },
  reducers: {
    handleLogin: (state, { payload }) => {
      state.status = 'authenticated';
      state.token = payload.token;
      state.user = payload.user;
    },
    handleLogout: (state, { payload }) => {
      state.status = 'not-authenticated';
      state.authErrorMessage = null;
      state.authStatus = 'pending';
      state.token = null;
      state.user = null;
      state.sessionStatus = 'offline';
      state.lastConnection = null;
    },
    handleStatusAuth: (state, { payload }) => {
      state.authErrorMessage = (payload) ? payload.error : null;
      state.authStatus = (payload) ? payload.status : 'pending';
    },
    handleSessionStatus: (state, { payload }) => {
      state.sessionStatus = (payload) ? payload.status : 'offline';
      state.lastConnection = (payload) ? payload.lastConnection : null;
    },
  }
});

export const { handleLogin, handleLogout, handleStatusAuth, handleSessionStatus } = authSlice.actions;