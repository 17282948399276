import { createSlice } from '@reduxjs/toolkit';

export const accountTypesSlice = createSlice({
  name: 'accountTypes',
  initialState: {
    accountTypesList: null,
    accountTypesRecordEdit: null,
    accountTypesShowModal: false,
    accountTypesErrorMessage: null,
    accountTypesStatus: 'pending'
  },
  reducers: {
    listAccountTypes: (state, { payload }) => {
      state.accountTypesList = (payload) ? payload : null;
    },
    statusAccountTypes: (state, { payload }) => {
      state.accountTypesErrorMessage = (payload) ? payload.error : null;
      state.accountTypesStatus = (payload) ? payload.status : 'pending';
    },
    recordEditAccountTypes: (state, { payload }) => {
      state.accountTypesRecordEdit = (payload) ? payload : null;
    },
    showModalAccountTypes: (state, { payload }) => {
      state.accountTypesShowModal = payload;
    },
  }
});

export const { listAccountTypes, statusAccountTypes, recordEditAccountTypes, showModalAccountTypes } = accountTypesSlice.actions;