import React, { Fragment, useEffect, useState  } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../layouts/layoutcomponents/header';
import Switcher from '../layouts/layoutcomponents/switcher';
import Sidebar from '../layouts/layoutcomponents/sidebar';
import SidebarApi from '../layouts/layoutcomponents/sidebarApi';
import Rightside from '../layouts/layoutcomponents/rightside';
import Footer from '../layouts/layoutcomponents/footer';
import BacktoTop from '../layouts/layoutcomponents/backtotop';


import { useCheckAuth } from '@/hooks/useCheckAuth';
import { useMenus } from '@/hooks/useMenus';


import { setSessionStatus, refreshToken } from '../redux/auth/thunks';


export const RightSideBarclose = () => {

  //rightsidebar close function
  document.querySelector(".sidebar-right").classList.remove("sidebar-open");

  //SwitcherMenu close function
  document.querySelector(".demo_changer").classList.remove("active");
  document.querySelector(".demo_changer").style.right = "-270px";

  if (document.querySelector(".card.search-result") != null) {
    document.querySelector(".card.search-result").classList.add("d-none")
  }
};

function App() {
  const { data, isFetching } = useMenus(1);
  const dispatch = useDispatch();
  const { sessionStatus, user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const status = useCheckAuth();
  const navigate = useNavigate();

  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0);

  document.querySelector("body")?.classList.remove('landing-page', 'horizontalmenu');

  const SideMenuIconclose = () => {
    document.querySelector(".app")?.classList.remove("sidenav-toggled");
  }

  if(status === 'not-authenticated'){
    navigate("/auth");
  }

  useEffect(() => {
    document.querySelector("body")?.classList.remove("ltr", "bg-primary-transparent", "error-page1", "main-body", "error-2");
  }, []);

  useEffect(() => {
    localStorage.setItem('lastPatch',pathname)
    if(status === 'not-authenticated'){
      navigate("/auth");
    }
  }, [pathname]);



  const onIdle = () => {
    let lastConnection = moment(getLastActiveTime()).format('YYYY-MM-DD H:mm:s');
    dispatch(setSessionStatus('Bussy', lastConnection));
    setState('Idle')
  }

  const onActive = () => {
    if(sessionStatus != 'Online'){
      dispatch(setSessionStatus('Online', null));
      //dispatch(refreshToken());
    }
    setState('Active')
  }

  const onAction = () => {
    if(sessionStatus != 'Online'){
      dispatch(setSessionStatus('Online', null));
    }
    setCount(count + 1)
  }

  const { getRemainingTime, getLastActiveTime, getLastIdleTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 60000,//120000,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    }
  })

  return (
    <Fragment>
      <div className='horizontalMenucontainer'>
        <div className="page">
          <Switcher />
          <Header />
          <div className="sticky" style={{ paddingTop: "-63px" }}>
            {/* <Sidebar /> */}
            <SidebarApi />
          </div>
          <div className="jumps-prevent" style={{ paddingTop: "63px" }}></div>
          <div className="main-content app-content" onClick={() => { RightSideBarclose(); SideMenuIconclose() }}>
            <div className="main-container container-fluid" >
              <div className='side-app'>
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Rightside />
        <BacktoTop />
      </div>
    </Fragment>
  )
}

export default App;
