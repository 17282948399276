import { createSlice } from '@reduxjs/toolkit';

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState: {
    departmentList: null,
    departmentRecordEdit: null,
    departmentShowModal: false,
    departmentErrorMessage: null,
    departmentStatus: 'pending'
  },
  reducers: {
    listDepartments: (state, { payload }) => {
      state.departmentList = (payload) ? payload : null;
    },
    statusDepartments: (state, { payload }) => {
      state.departmentErrorMessage = (payload) ? payload.error : null;
      state.departmentStatus = (payload) ? payload.status : 'pending';
    },
    recordEditDepartments: (state, { payload }) => {
      state.departmentRecordEdit = (payload) ? payload : null;
    },
    showModalDepartments: (state, { payload }) => {
      state.departmentShowModal = payload;
    },
  }
});

export const { listDepartments, statusDepartments, recordEditDepartments, showModalDepartments } = departmentsSlice.actions;