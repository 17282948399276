import { createSlice } from '@reduxjs/toolkit';

export const filesSlice = createSlice({
  name: 'files',
  initialState: {
    filesList: null,
    filesRecordEdit: null,
    filesShowModal: false,
    filesErrorMessage: null,
    filesStatus: 'pending'
  },
  reducers: {
    listFiles: (state, { payload }) => {
      state.filesList = (payload) ? payload : null;
    },
    statusFiles: (state, { payload }) => {
      state.filesErrorMessage = (payload) ? payload.error : null;
      state.filesStatus = (payload) ? payload.status : 'pending';
    },
    recordEditFiles: (state, { payload }) => {
      state.filesRecordEdit = (payload) ? payload : null;
    },
    showModalFiles: (state, { payload }) => {
      state.filesShowModal = payload;
    },
  }
});

export const { listFiles, statusFiles, recordEditFiles, showModalFiles } = filesSlice.actions;