import { createSlice } from '@reduxjs/toolkit';

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    tagsList: null,
    tagsRecordEdit: null,
    tagsShowModal: false,
    tagsErrorMessage: null,
    tagsStatus: 'pending'
  },
  reducers: {
    listTags: (state, { payload }) => {
      state.tagsList = (payload) ? payload : null;
    },
    statusTags: (state, { payload }) => {
      state.tagsErrorMessage = (payload) ? payload.error : null;
      state.tagsStatus = (payload) ? payload.status : 'pending';
    },
    recordEditTags: (state, { payload }) => {
      state.tagsRecordEdit = (payload) ? payload : null;
    },
    showModalTags: (state, { payload }) => {
      state.tagsShowModal = payload;
    },
  }
});

export const { listTags, statusTags, recordEditTags, showModalTags } = tagsSlice.actions;