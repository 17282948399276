import { ApiCore } from './ApiCore';

const Api = new ApiCore();

//Auth
export const checkUser = ()=> Api.post(`/check`, {});

//Countries
export const fetchCountries = ()=> Api.getNotAuth(`/countries`);
export const fetchRegionsByCountry = (id)=> Api.getNotAuth(`/regions-by-country/${id}`);
export const fetchCitiesByRegions = (id)=> Api.getNotAuth(`/cities-by-region/${id}`);


//Files
export const fetchFiles = ()=> Api.getFiles();
export const uploadFiles = (data)=> Api.postFiles(`/files`, data);
export const fetchIcons = ()=> Api.get(`/files/icons`);

//Modules
export const fetchModules = ()=> Api.get(`/modules`);

//Menus del sistema
export const fetchMenus = ()=> Api.get(`/menus`);
export const fetchMenusById = (id)=> Api.get(`/menus/${id}`);
export const createMenus = (data)=> Api.post(`/menus`, data);
export const updateMenus = (data)=> Api.put(`/menus`, data);
export const deleteMenus = (id)=> Api.delete(`/menus/${id}`);

//Maestro de Estatus
export const fetchStatus = ()=> Api.get(`/statues`);
export const fetchStatusById = (id)=> Api.get(`/statues/${id}`);
export const createStatus = (data)=> Api.post(`/statues`, data);
export const updateStatus = (data)=> Api.put(`/statues`, data);
export const deleteStatus = (id)=> Api.delete(`/statues/${id}`);

//Maestro de Tipos de tareas
export const fetchTaskType = ()=> Api.get(`/types_task`);
export const fetchTaskTypeById = (id)=> Api.get(`/types_task/${id}`);
export const createTaskType = (data)=> Api.post(`/types_task`, data);
export const updateTaskType = (data)=> Api.put(`/types_task`, data);
export const deleteTaskType = (id)=> Api.delete(`/types_task/${id}`);

//Maestro de tareas
export const fetchTasks = ()=> Api.get(`/tasks`);
export const fetchTasksById = (id)=> Api.get(`/tasks/${id}`);
export const createTasks = (data)=> Api.post(`/tasks`, data);
export const updateTasks = (data)=> Api.put(`/tasks`, data);
export const deleteTasks = (id)=> Api.delete(`/tasks/${id}`);
export const searchTasks = (data)=> Api.post(`/tasks/search`, data);

//Maestro de subtareas
export const fetchSubTasks = ()=> Api.get(`/sub-tasks`);
export const fetchSubTasksById = (id)=> Api.get(`/sub-tasks/${id}`);
export const createSubTasks = (data)=> Api.post(`/sub-tasks`, data);
export const updateSubTasks = (data)=> Api.put(`/sub-tasks`, data);
export const deleteSubTasks = (id)=> Api.delete(`/sub-tasks/${id}`);


//Maestro de espacio de trabajo
export const fetchWorkSpaces = ()=> Api.get(`/workspaces`);
export const fetchWorkSpacesById = (id)=> Api.get(`/workspaces/${id}`);
export const createWorkSpaces = (data)=> Api.post(`/workspaces`, data);
export const updateWorkSpaces = (data)=> Api.put(`/workspaces`, data);
export const deleteWorkSpaces = (id)=> Api.delete(`/workspaces/${id}`);

//Maestro de sitios
export const fetchSites = ()=> Api.get(`/sites`);
export const fetchSitesById = (id)=> Api.get(`/sites/${id}`);
export const createSites = (data)=> Api.post(`/sites`, data);
export const updateSites = (data)=> Api.put(`/sites`, data);
export const deleteSites = (id)=> Api.delete(`/sites/${id}`);

//Maestro de departamentos
export const fetchDepartments = ()=> Api.get(`/departments`);
export const fetchDepartmentsById = (id)=> Api.get(`/departments/${id}`);
export const createDepartments = (data)=> Api.post(`/departments`, data);
export const updateDepartments = (data)=> Api.put(`/departments`, data);
export const deleteDepartments = (id)=> Api.delete(`/departments/${id}`);

//Maestro de prioridades
export const fetchPrioritys = ()=> Api.get(`/priority`);
export const fetchPrioritysById = (id)=> Api.get(`/priority/${id}`);
export const createPrioritys = (data)=> Api.post(`/priority`, data);
export const updatePrioritys = (data)=> Api.put(`/priority`, data);
export const deletePrioritys = (id)=> Api.delete(`/priority/${id}`);

//Maestro de tags
export const fetchTags = ()=> Api.get(`/tags`);
export const fetchTagsById = (id)=> Api.get(`/tags/${id}`);
export const createTags = (data)=> Api.post(`/tags`, data);
export const updateTags = (data)=> Api.put(`/tags`, data);
export const deleteTags = (id)=> Api.delete(`/tags/${id}`);

//Maestro de funciones/actividades
export const fetchFunctions = ()=> Api.get(`/functions`);
export const fetchFunctionsById = (id)=> Api.get(`/functions/${id}`);
export const createFunctions = (data)=> Api.post(`/functions`, data);
export const updateFunctions = (data)=> Api.put(`/functions`, data);
export const deleteFunctions = (id)=> Api.delete(`/functions/${id}`);

//Maestro de cargos
export const fetchPositions = ()=> Api.get(`/positions`);
export const fetchPositionsById = (id)=> Api.get(`/positions/${id}`);
export const createPositions = (data)=> Api.post(`/positions`, data);
export const updatePositions = (data)=> Api.put(`/positions`, data);
export const deletePositions = (id)=> Api.delete(`/positions/${id}`);

//maestro de redes sociales
export const fetchSocialNetwork = ()=> Api.get(`/social-network`);
export const fetchSocialNetworkById = (id)=> Api.get(`/social-network/${id}`);
export const createSocialNetwork = (data)=> Api.post(`/social-network`, data);
export const updateSocialNetwork = (data)=> Api.put(`/social-network`, data);
export const deleteSocialNetwork = (id)=> Api.delete(`/social-network/${id}`);

//maestro de tipos de cuentas
export const fetchAccountTypes = ()=> Api.get(`/account_types`);
export const fetchAccountTypesById = (id)=> Api.get(`/account_types/${id}`);
export const createAccountTypes = (data)=> Api.post(`/account_types`, data);
export const updateAccountTypes = (data)=> Api.put(`/account_types`, data);
export const deleteAccountTypes = (id)=> Api.delete(`/account_types/${id}`);

//maestro de usuarios
export const fetchUsers = ()=> Api.get(`/users`);
export const fetchUsersById = (id)=> Api.get(`/users/${id}`);
export const createUsers = (data)=> Api.post(`/users`, data);
export const updateUsers = (data)=> Api.put(`/users`, data);
export const deleteUsers = (id)=> Api.delete(`/users/${id}`);
export const resendEmailVerify = (id)=> Api.post(`/users/revalidate/${id}`, {});

//maestro de roles
export const fetchRoles = ()=> Api.get(`/roles`);
export const fetchRolesById = (id)=> Api.get(`/roles/${id}`);
export const createRoles = (data)=> Api.post(`/roles`, data);
export const updateRoles = (data)=> Api.put(`/roles`, data);
export const deleteRoles = (id)=> Api.delete(`/roles/${id}`);

//Reportes Externos
export const fetchExternalReports = ()=> Api.get(`/external-report`);
export const fetchExternalReportsById = (id)=> Api.get(`/external-report/${id}`);
export const createExternalReports = (data)=> Api.post(`/external-report`, data);
export const updateExternalReports = (data)=> Api.put(`/external-report`, data);
export const deleteExternalReports = (id)=> Api.delete(`/external-report/${id}`);

//Cuentas
export const fetchAccounts = ()=> Api.get(`/account`);
export const fetchAccountsById = (id = 0)=> Api.get(`/account/${id}`);
export const createAccounts = (data)=> Api.post(`/account`, data);
export const updateAccounts = (data)=> Api.put(`/account`, data);
export const deleteAccounts = (id = 0)=> Api.delete(`/account/${id}`);
export const searchAccounts = (data)=> Api.post(`/account/search`, data);

//Comentarios
export const fetchComments = ()=> Api.get(`/comments`);
export const fetchCommentsById = (id)=> Api.get(`/comments/${id}`);
export const createComments = (data)=> Api.post(`/comments`, data);
export const updateComments = (data)=> Api.put(`/comments`, data);
export const deleteComments = (id)=> Api.delete(`/comments/${id}`);

//Personas
export const fetchPersons = ()=> Api.get(`/employees`);
export const fetchPersonsById = (id)=> Api.get(`/employees/${id}`);
export const createPersons = (data)=> Api.post(`/employees`, data);
export const updatePersons = (data)=> Api.put(`/employees`, data);
export const deletePersons = (id)=> Api.delete(`/employees/${id}`);
export const searchPersons = (data)=> Api.post(`/employees/search`, data);

//Task Manager
export const fetchTaskManager = ()=> Api.get(`/tasks-manager`);
export const fetchTaskManagerById = (id)=> Api.get(`/tasks-manager/${id}`);
export const createTaskManager = (data)=> Api.post(`/tasks-manager`, data);
export const updateTaskManager = (data)=> Api.put(`/tasks-manager`, data);
export const deleteTaskManager = (id)=> Api.delete(`/tasks-manager/${id}`);
export const searchTaskManager = (data)=> Api.post(`/tasks-manager/search`, data);
export const updateStatusTaskManager = (data) => Api.put(`/tasks-manager/update-status/${data.id}`, data);
export const updateStatusMassiveTaskManager = (data) => Api.put(`/tasks-manager/update-status-massive`, data);
export const updateResponsibleMassiveTaskManager = (data) => Api.put(`/tasks-manager/update-responsible-massive`, data);
export const fetchTaskManagerDetail = ()=> Api.get(`/tasks-manager-detail`);
export const searchTaskManagerDetail = (data)=> Api.post(`/tasks-manager-detail/search`, data);

//Ecommerce-categories
export const getCategories = () => Api.getNotAuth(`/ecommerce/categories`);
export const getCategoriesById = (id) => Api.getNotAuth(`/ecommerce/categories/${id}`);
export const createCategories = (data)=> Api.postFormData(`/ecommerce/categories/create`, data);
export const updateCategories = (data)=> Api.postFormData(`/ecommerce/categories/update`, data);
export const deleteCategories = (id)=> Api.delete(`/ecommerce/categories/${id}`);

//Ecommerce-products
export const getProducts = () => Api.getNotAuth(`/ecommerce/products`);
export const getProductsById = (id) => Api.getNotAuth(`/ecommerce/products/${id}`);
export const getProductsBySlug = (slug) => Api.getNotAuth(`/ecommerce/products/slug/${slug}`);
export const getProductsSearch = (search) => Api.getNotAuth(`/ecommerce/products/search/?search=${search}`);
export const createProducts = (data)=> Api.postFormData(`/ecommerce/products/create`, data);
export const updateProducts = (data)=> Api.postFormData(`/ecommerce/products/update`, data);
export const deleteProducts = (id)=> Api.delete(`/ecommerce/products/${id}`);

//Ecommerce-wishlist
export const getWishlist = () => Api.get(`/ecommerce/wishlist`);
export const getAllWishlistByUserId = (userId) => Api.get(`/ecommerce/wishlist/${userId}`);
export const getSingleProductFromWishlist = (userId, productId) => Api.get(`/ecommerce/wishlist/${userId}/${productId}`);
export const createWishlist = (data)=> Api.post(`/ecommerce/wishlist`, data);
export const deleteWishlist = (userId, productId)=> Api.delete(`/ecommerce/wishlist/${userId}/${productId}`);

//Ecommerce-stores
export const fetchStores = ()=> Api.getNotAuth(`/ecommerce/stores`);
export const fetchStoresNotAccount = ()=> Api.getNotAuth(`/ecommerce/stores-account-empty`);
export const fetchStoresById = (id)=> Api.getNotAuth(`/ecommerce/stores/${id}`);
export const createStores = (data)=> Api.post(`/ecommerce/stores`, data);
export const updateStores = (data)=> Api.put(`/ecommerce/stores`, data);
export const deleteStores = (id)=> Api.delete(`/ecommerce/stores/${id}`);

//Ecommerce-brands
export const fetchBrands = ()=> Api.getNotAuth(`/ecommerce/brands`);
export const fetchBrandsById = (id)=> Api.getNotAuth(`/ecommerce/brands/${id}`);
export const createBrands = (data)=> Api.post(`/ecommerce/brands`, data);
export const updateBrands = (data)=> Api.put(`/ecommerce/brands`, data);
export const deleteBrands = (id)=> Api.delete(`/ecommerce/brands/${id}`);

//Ecommerce-tags
export const fetchTagsEcom = ()=> Api.getNotAuth(`/ecommerce/tags`);
export const fetchTagsEcomById = (id)=> Api.getNotAuth(`/ecommerce/tags/${id}`);
export const createTagsEcom = (data)=> Api.post(`/ecommerce/tags`, data);
export const updateTagsEcom = (data)=> Api.put(`/ecommerce/tags`, data);
export const deleteTagsEcom = (id)=> Api.delete(`/ecommerce/tags/${id}`);

//Ecommerce-taxes
export const fetchTaxes = ()=> Api.getNotAuth(`/ecommerce/taxes`);
export const fetchTaxesById = (id)=> Api.getNotAuth(`/ecommerce/taxes/${id}`);
export const createTaxes = (data)=> Api.post(`/ecommerce/taxes`, data);
export const updateTaxes = (data)=> Api.put(`/ecommerce/taxes`, data);
export const deleteTaxes = (id)=> Api.delete(`/ecommerce/taxes/${id}`);

//Ecommerce-manufacturers
export const fetchManufacturersEcom = ()=> Api.getNotAuth(`/ecommerce/manufacturers`);
export const fetchManufacturersEcomById = (id)=> Api.getNotAuth(`/ecommerce/manufacturers/${id}`);
export const createManufacturersEcom = (data)=> Api.post(`/ecommerce/manufacturers`, data);
export const updateManufacturersEcom = (data)=> Api.put(`/ecommerce/manufacturers`, data);
export const deleteManufacturersEcom = (id)=> Api.delete(`/ecommerce/manufacturers/${id}`);

//Ecommerce-prices-list
export const getPricesList = () => Api.get(`/ecommerce/prices-list`);
export const getPricesListById = (id) => Api.get(`/ecommerce/prices-list/${id}`);
export const createPricesList = (data)=> Api.postFormData(`/ecommerce/prices-list/create`, data);
export const updatePricesList = (data)=> Api.postFormData(`/ecommerce/prices-list/update`, data);
export const deletePricesList = (id)=> Api.delete(`/ecommerce/prices-list/${id}`);

//Ecommerce-settings
export const getSettings = () => Api.getNotAuth(`/ecommerce/settings`);
export const createSettings = (data)=> Api.postFormData(`/ecommerce/settings/create`, data);
export const updateSettings = (data)=> Api.postFormData(`/ecommerce/settings/update`, data);

//Ecommerce-orders
export const getOrders = () => Api.get(`/ecommerce/orders`);
export const getOrdersSearch = (params) => Api.get(`/ecommerce/orders-search?${params}`);
export const getOrder = (id)=> Api.get(`/ecommerce/orders/${id}`, {});
export const createOrders = (data)=> Api.post(`/ecommerce/orders`, data);
export const UpdateOrders = (data)=> Api.put(`/ecommerce/orders`, data);
export const deleteOrders = (id)=> Api.delete(`/ecommerce/orders/${id}`);

//Ecommerce-users
export const getUsersEcommerce = () => Api.get(`/ecommerce/users`);