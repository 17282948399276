import { createSlice } from '@reduxjs/toolkit';

export const tasksTypesSlice = createSlice({
  name: 'tasksTypes',
  initialState: {
    tasksTypesList: null,
    tasksTypesRecordEdit: null,
    tasksTypesShowModal: false,
    tasksTypesErrorMessage: null,
    tasksTypesStatus: 'pending'
  },
  reducers: {
    listTasksTypes: (state, { payload }) => {
      state.tasksTypesList = (payload) ? payload : null;
    },
    statusTasksTypes: (state, { payload }) => {
      state.tasksTypesErrorMessage = (payload) ? payload.error : null;
      state.tasksTypesStatus = (payload) ? payload.status : 'pending';
    },
    recordEditTasksTypes: (state, { payload }) => {
      state.tasksTypesRecordEdit = (payload) ? payload : null;
    },
    showModalTasksTypes: (state, { payload }) => {
      state.tasksTypesShowModal = payload;
    },
  }
});

export const { listTasksTypes, statusTasksTypes, recordEditTasksTypes, showModalTasksTypes } = tasksTypesSlice.actions;