import { createSlice } from '@reduxjs/toolkit';

export const socialNetworkSlice = createSlice({
  name: 'socialNetwork',
  initialState: {
    socialNetworkList: null,
    socialNetworkRecordEdit: null,
    socialNetworkShowModal: false,
    socialNetworkErrorMessage: null,
    socialNetworkStatus: 'pending'
  },
  reducers: {
    listSocialNetwork: (state, { payload }) => {
      state.socialNetworkList = (payload) ? payload : null;
    },
    statusSocialNetwork: (state, { payload }) => {
      state.socialNetworkErrorMessage = (payload) ? payload.error : null;
      state.socialNetworkStatus = (payload) ? payload.status : 'pending';
    },
    recordEditSocialNetwork: (state, { payload }) => {
      state.socialNetworkRecordEdit = (payload) ? payload : null;
    },
    showModalSocialNetwork: (state, { payload }) => {
      state.socialNetworkShowModal = payload;
    },
  }
});

export const { listSocialNetwork, statusSocialNetwork, recordEditSocialNetwork, showModalSocialNetwork } = socialNetworkSlice.actions;