import { createStore, applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import reducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import logger from 'redux-logger'

import { authSlice } from './auth/authSlice';
import { usersSlice } from './users/usersSlice';
import { rolesSlice } from './roles/rolesSlice';
import { countrySlice } from './countries/countrySlice';
import { regionsSlice } from './regions/regionsSlice';
import { citiesSlice } from './cities/citiesSlice';
import { permissionsSlice } from "./permissions/permissionsSlice";
import { statusSlice } from "./status/statusSlice";
import { taskSlice } from "./task/taskSlice";
import { personSlice } from "./person/personSlice";
import { taskManagerSlice } from "./task-manager/taskManagerSlice";
import { subTaskSlice } from "./sub-task/subTaskSlice";
import { siteSlice } from "./sites/siteSlice";
import { workspacesSlice } from "./workspaces/workspacesSlice";
import { departmentsSlice } from "./departments/departmentsSlice";
import { prioritySlice } from "./priority/prioritySlice";
import { commentsSlice } from "./comments/commentsSlice";
import { tagsSlice } from "./tags/tagsSlice";
import { functionsSlice } from "./functions/functionsSlice";
import { positionsSlice } from "./positions/positionsSlice";
import { tasksTypesSlice } from "./task-types/tasksTypesSlice";
import { accountTypesSlice } from "./account-types/accountTypesSlice";
import { accountSlice } from "./account/accountSlice";
import { socialNetworkSlice } from "./social-network/socialNetworkSlice";
import { filesSlice } from "./files/filesSlice";


/* const middleware = [thunk]

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store */

export const store = configureStore({
  reducer: {
    //reducer,
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    roles: rolesSlice.reducer,
    country: countrySlice.reducer,
    regions: regionsSlice.reducer,
    cities: citiesSlice.reducer,
    account: accountSlice.reducer,
    permissions: permissionsSlice.reducer,
    status: statusSlice.reducer,
    task: taskSlice.reducer,
    subTask: subTaskSlice.reducer,
    person: personSlice.reducer,
    taskManager: taskManagerSlice.reducer,
    sites: siteSlice.reducer,
    workspaces: workspacesSlice.reducer,
    departments: departmentsSlice.reducer,
    priority: prioritySlice.reducer,
    comments: commentsSlice.reducer,
    tags: tagsSlice.reducer,
    functions: functionsSlice.reducer,
    positions: positionsSlice.reducer,
    tasksTypes: tasksTypesSlice.reducer,
    accountTypes: accountTypesSlice.reducer,
    socialNetwork: socialNetworkSlice.reducer,
    files: filesSlice.reducer,
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});