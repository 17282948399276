import { createSlice } from '@reduxjs/toolkit';

export const personSlice = createSlice({
  name: 'person',
  initialState: {
    personList: null,
    personRecordEdit: null,
    personShowModal: false,
    personErrorMessage: null,
    personStatus: 'pending'
  },
  reducers: {
    listPersons: (state, { payload }) => {
      state.personList = (payload) ? payload : null;
    },
    statusPersons: (state, { payload }) => {
      state.personErrorMessage = (payload) ? payload.error : null;
      state.personStatus = (payload) ? payload.status : 'pending';
    },
    recordEditPersons: (state, { payload }) => {
      state.personRecordEdit = (payload) ? payload : null;
    },
    showModalPersons: (state, { payload }) => {
      state.personShowModal = payload;
    },
  }
});

export const { listPersons, statusPersons, recordEditPersons, showModalPersons } = personSlice.actions;