import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchMenus, fetchMenusById, createMenus, updateMenus, deleteMenus } from "../api";


export const useMenusList = () => {
  const query = useQuery(
    ['menus', 'list'],
    () => fetchMenus(),
    {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  );

  return query;
};

export const useMenus = (id) => {

  const query = useQuery(
    ['menus', id ],
    () => fetchMenusById(id),
    {
      staleTime: 1000 * 60 * 60,
    }
  );

  return query;
}

export const useMenusCreate = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['menus', 'create'],
    mutationFn: createMenus,
    onSuccess: (menus, variables, context ) => {
      queryClient.invalidateQueries(
        ['menus', 'list'],
      );
    },
  });

  return query;
};

export const useMenusUpdate = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['menus', 'update'],
    mutationFn: updateMenus,
    onSuccess: (menus, variables, context ) => {
      queryClient.invalidateQueries(
        ['menus', 'list'],
      );
      queryClient.removeQueries(
        ['menus', variables.id],
      );
    },
  });

  return query;
};

export const useMenusDelete = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['menus', 'delete'],
    mutationFn: deleteMenus,
    onMutate: (menus) => {
      queryClient.setQueryData(
        ['menus', 'list'],
        (old) => {
          if ( !old ) return [];
          return old.filter( (item) => item.id !== menus );
        });
    },
    onSuccess: (menus, variables, context ) => {
      queryClient.invalidateQueries(
        ['menus', 'list'],
      );
    },
    onError: (error, variables, context ) => {
      queryClient.invalidateQueries(
        ['menus', 'list'],
      );
    }
  });

  return query;
};