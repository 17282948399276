import { createSlice } from '@reduxjs/toolkit';

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    permissionList: null,
    modulesList: null,
    permissionRecordEdit: null,
    permissionShowModal: false,
    permissionErrorMessage: null,
    permissionStatus: 'pending'
  },
  reducers: {
    listPermissions: (state, { payload }) => {
      state.permissionList = (payload) ? payload : null;
    },
    listModules: (state, { payload }) => {
      state.modulesList = (payload) ? payload : null;
    },
    statusPermissions: (state, { payload }) => {
      state.permissionErrorMessage = (payload) ? payload.error : null;
      state.permissionStatus = (payload) ? payload.status : 'pending';
    },
    recordEditPermissions: (state, { payload }) => {
      state.permissionRecordEdit = (payload) ? payload : null;
    },
    showModalPermissions: (state, { payload }) => {
      state.permissionShowModal = payload;
    },
  }
});

export const { listPermissions, listModules, statusPermissions, recordEditPermissions, showModalPermissions } = permissionsSlice.actions;