const locale = {
  "Dashboard": "Dashboard",
  "Accounts"  : "Accounts",
  "Employees": "Employees",
  "Task Mangement" : "Task Mangement",
  "Lead" : "Lead",
  "Files" : "Files",
  
  "Settings": "Settings",
  "Status": "Status",
  "Task type": "Task type",
  "Tasks": "Tasks",
  "Subtasks": "Subtasks",
  "Workspaces": "Workspaces",
  "Sites": "Sites",
  "Departments": "Departments",
  "Users": "Users",
  "Role": "Role",
  "Tags": "Tags",
  "Functions": "Functions",
  "Positions": "Positions",
  "Priority": "Priority",
  "Indicators": 'Indicators',
  "Account type": 'Account type',
  "Social Network": "Social Network",
  "Persons": "Persons",
  "External Reports": "External Reports",
  "Register": "Register",
  "View": "View",
  "Ecommerce": "Ecommerce",
  "Setting": "Setting",
  "Categories": "Categories",
  "Products": "Products",
  "Stores": "Stores",
  "Brands": "Brands",
  "Taxes": "Taxes",
  "Manufacturers": "Manufacturers",
  "Price list": "Price list",
  "Orders": "Orders",

};

export default locale;
