import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";


const langSelected = localStorage.getItem('i18nextLng');

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      'Showing': 'Showing',
    }
  },
  es: {
    translation: {
      'Welcome to React': 'Bienvenido a React y react-i18next',
      'Showing': 'Mostrando',
    }
  } 
};

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  lng: langSelected,
  resources,
  debug: false,
  whitelist: ['en', 'es'],
  react: {
    useSuspense: false,
  },
});

export default i18next;